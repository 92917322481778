import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import loadable from '@loadable/component';
import { Article } from 'common/components/schemaOrg/Article';
import { Top100Context } from 'common/contexts/top100Context';

import DefaultPage from '../Default';

import s from './index.css';

const Card = loadable(() => import('common/components/Card'));

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isPremium,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.top100,
  ],
  (
    isPremium,
    currentPage,
    currentSection,
    top100,
  ) => ({
    isPremium,
    currentPage,
    currentSection,
    top100,
  }),
);

const selectPageData = createSelector(
  (
    state: IAppState,
    currentPage: RuntimeType['currentPage'],
    currentSection: RuntimeType['currentSection'],
  ) => state.pages[currentPage][currentSection],
  data => data,
);

function LongreadDetailed() {
  const {
    isPremium,
    currentPage,
    currentSection,
    top100,
  } = useSelector(selectData);

  const data = useSelector(
    (state: IAppState) => selectPageData(state, currentPage, currentSection),
  );

  const mainCardData = isPremium ? data!.content : null;
  const top100Prefix = isPremium ? `${top100}_${mainCardData ? 'content_page' : 'list_page'}` : '';
  const memoTop100Prefix = useMemo(() => ({ top100Prefix: `${top100Prefix}::content_card::page` }), [top100Prefix]);

  if (isPremium && mainCardData) {
    return (
      <div itemScope itemType="http://schema.org/Article">
        <Article />
        <Top100Context.Provider value={memoTop100Prefix}>
          <Card
            {...mainCardData}
            isMainCard
            isLazy={false}
            isNeedLongreadTopicList={false}
            className={s.card}
            isPremium
          />
        </Top100Context.Provider>
      </div>
    );
  }

  return <DefaultPage withSchema />;
}

export default LongreadDetailed;
