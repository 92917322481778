import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { CardHeader } from 'common/components/Card/Header';
import { Footer } from 'common/components/Card/Footer';
import { Tags } from 'common/components/Card/Tags';
import { HumanDesignDetailed } from 'common/components/HumanDesign/Detailed';
import { HumanDesignBubbles } from 'common/components/HumanDesign/Bubbles';
import { HumanDesignTypes } from 'common/components/HumanDesign/Types';
import { HumanDesignForm } from 'common/components/HumanDesign/Form';

import DefaultPage from '../../Default';

import s from './index.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.humanDesign,
  ],
  humanDesign => ({
    humanDesign,
  }),
);

const selectPageData = createSelector(
  (
    state: IAppState,
    currentPage: RuntimeType['currentPage'],
    currentSection: RuntimeType['currentSection'],
  ) => state.pages[currentPage][currentSection],
  data => data,
);

function HumanDesignProfilePage() {
  const {
    humanDesign,
  } = useSelector(selectData);

  const data = useSelector((state: IAppState) => selectPageData(state, 'humandesign', 'profile'));

  if (!humanDesign || !data) return null;

  const { content } = data;
  const isMobile = false;

  const {
    title,
    link,
    type,
    is_h2_title: isH2Title,
    is_h3_title: isH3Title,
    isMainCard,
    isSubCard,
    tags,
    likes,
  } = content;

  return (
    <DefaultPage
      withSchema
      withoutMainCard
      centerColumn={(
        <div className={s.card}>
          <CardHeader
            isMobile={isMobile}
            titleProps={{
              title,
              isMobile,
              link,
              type,
              isLink:    false,
              isH2Title,
              isH3Title,
              isMainCard,
              isH1Title: true,
              isHanding: false,
              isSubCard,
            }}
            socialProps={{
              links: {},
              isMobile,
            }}
          />
          <HumanDesignDetailed
            detailed={humanDesign.detailed}
            type="profiles"
            isMobile={isMobile}
          />
          <HumanDesignBubbles
            list={humanDesign.profiles}
            type="profiles"
            isMobile={isMobile}
          />
          <HumanDesignTypes
            types={humanDesign.types}
            limit={2}
            isMobile={isMobile}
          />
          <HumanDesignForm isMobile={isMobile} isExperimental={false} />
          <Tags
            wrapperClassName={s.tags}
            tags={tags}
            isMobile={isMobile}
          />
          <Footer
            className={cn(
              s.footer,
              s.footerDesktop,
            )}
            likesClassName={s.likes}
            title={title}
            link={link}
            likes={likes}
            isMobile={isMobile}
            key={likes ? likes.codename : ''}
          />
        </div>
      )}
    />
  );
}

export default HumanDesignProfilePage;
