import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import DefaultPage from 'desktop/pages/Default';
import { CardHeader } from 'common/components/Card/Header';
import { Footer } from 'common/components/Card/Footer';
import { Tags } from 'common/components/Card/Tags';
import { TarotCardPreview } from 'common/components/Tarot/TarotCardPreview';

import { TableOfContents } from 'common/components/TableOfContents';
import parseDraft from 'common/utils/draftParser';
import { DraftText } from 'common/components/DraftText';
import { getCurrentTarot } from 'config/constants/tarot';

import s from './styles.css';

const selectPageData = createSelector(
  (
    state: IAppState,
    currentPage: RuntimeType['currentPage'],
    currentSection: RuntimeType['currentSection'],
  ) => state.pages[currentPage][currentSection],
  data => data,
);

const selectCurrentCard = createSelector((state: IAppState) => state.runtime.currentParams.url, url => getCurrentTarot('code', url));

function TarotCardPage() {
  const data = useSelector((state: IAppState) => selectPageData(state, 'fortune', 'taroonecard'));
  const currentCard = useSelector(selectCurrentCard);

  if (!data || !Object.values(currentCard).length) return null;

  const { content } = data;
  const isMobile = false;

  const {
    title,
    link,
    likes,
    draft,
    tags,
    id,
    type,
  } = content;

  const draftParsed = draft
    ? parseDraft(
      draft,
      id,
      isMobile,
      undefined,
      true,
    )
    : '';

  return (
    <DefaultPage
      withSchema
      withoutMainCard
      withTarotMenu
      withoutBubbles
      withoutPersonal
      centerColumn={(
        <div className={s.card}>
          <CardHeader
            isMobile={isMobile}
            titleProps={{
              title:      `${currentCard.name} Таро – значение и сочетание карты`,
              isMobile,
              type,
              isLink:     false,
              isMainCard: true,
              isH1Title:  true,
              isHanding:  false,
            }}
            socialProps={{
              isMobile,
            }}
          />
          <TarotCardPreview id={currentCard.id} isMobile={isMobile} />
          <TableOfContents
            draft={draft}
            isMobile={isMobile}
            className={s.tableOfContents}
          />
          <DraftText
            data={draftParsed}
            title={title}
            className={s.draftText}
            isMainCard
          />
          <Tags
            wrapperClassName={s.tags}
            tags={tags}
            isMobile={isMobile}
          />
          <Footer
            title={title}
            link={link}
            likes={likes}
            isMobile={isMobile}
            className={s.footer}
            key={likes ? likes.codename : ''}
          />
        </div>
      )}
    />
  );
}

export default TarotCardPage;
